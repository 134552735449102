import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faMap, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

function Footer({ navigateTo }) {
    const location = useLocation();
    const guide_id = location.pathname.split('/')[1]; // Extract guide_id from the path

    return (
        <div className="footer h-15 max-h-15 flex justify-around items-center bg-gray-800 text-white w-full fixed bottom-0 border-t border-gray-700">
            <button className={`flex flex-grow flex-col items-center p-2 rounded ${location.pathname === `/${guide_id}` ? 'bg-gray-700' : ''}`} onClick={() => navigateTo(`/${guide_id}`)}>
                <FontAwesomeIcon icon={faHome} />
                <span className="text-sm mt-1">Home</span>
            </button>
            <button className={`flex flex-grow flex-col items-center p-2 rounded ${location.pathname === `/${guide_id}/info` ? 'bg-gray-700' : ''}`} onClick={() => navigateTo(`/${guide_id}/info`)}>
                <FontAwesomeIcon icon={faInfoCircle} />
                <span className="text-sm mt-1">Info</span>
            </button>
            <button className={`flex flex-grow flex-col items-center p-2 rounded ${location.pathname === `/${guide_id}/map` ? 'bg-gray-700' : ''}`} onClick={() => navigateTo(`/${guide_id}/map`)}>
                <FontAwesomeIcon icon={faMap} />
                <span className="text-sm mt-1">Map</span>
            </button>
            <button className={`flex flex-grow flex-col items-center p-2 rounded ${location.pathname === `/${guide_id}/search` ? 'bg-gray-700' : ''}`} onClick={() => navigateTo(`/${guide_id}/search`)}>
                <FontAwesomeIcon icon={faSearch} />
                <span className="text-sm mt-1">Search</span>
            </button>
        </div>
    );
}

export default Footer;
