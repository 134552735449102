import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faComment, faSun } from '@fortawesome/free-solid-svg-icons'

const TopBar = () => {
    return (
        <div className="flex justify-between items-center p-4 bg-blue-500 text-white">
            <div>
                <h1 className="font-bold text-lg">Logo</h1>
            </div>
            <div className="space-x-4">
                <FontAwesomeIcon icon={faPhone} />
                <FontAwesomeIcon icon={faEnvelope} />
                <FontAwesomeIcon icon={faComment} />
            </div>
            <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faSun} />
                <p>27°C Sunny</p>
            </div>
        </div>
    )
}

export default TopBar;
