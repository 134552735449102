import { createContext, useContext } from 'react';
import { createClient } from '@sanity/client';

// export const SanityClientContext = createContext();

// export const SanityClientProvider = ({ children }) => {
    
//     return (
//         <SanityClientContext.Provider value={client}>
//             {children}
//         </SanityClientContext.Provider>
//     );
// };
const client = createClient({
    projectId: '5jjvuf7e',
    dataset: 'production',
    useCdn: false, 
    apiVersion: 'v2021-10-21',
});

const SanityClientContext = createContext(client);
export const useSanityClient = () => useContext(SanityClientContext);
export default SanityClientContext.Provider;

// export const useSanityClient = () => useContext(SanityClientContext);
