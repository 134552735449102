import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PortableText } from '@portabletext/react'
import { useSanityClient } from '../components/SanityClientContext';
import imageUrlBuilder from '@sanity/image-url';

const myPortableTextComponents = {
    types: {
        image: ({ value }) => <img src={value.imageUrl} />,
        callToAction: ({ value, isInline }) =>
            isInline ? (
                <a href={value.url}>{value.text}</a>
            ) : (
                <div className="callToAction">{value.text}</div>
            ),
    },

    marks: {
        link: ({ children, value }) => {
            const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
            return (
                <a href={value.href} rel={rel}>
                    {children}
                </a>
            )
        },
    },
}

function urlFor(client, source) {
    return imageUrlBuilder(client).image(source);
}

function SubcategoryDetails({ selectedSubcategory, goBack }) {
    const client = useSanityClient();

    if (!selectedSubcategory) {
        return null; // or replace with a loading indicator, or any other component you want to show when there's no selected subcategory
    }


    return (
        <div className="lg:ml-8 min-h-screen bg-white rounded-t-none p-4  overflow-y-scroll">
            <div>
                <div className="mb-2">
                    <button className="lg:hidden text-black pl-2 pr-4" onClick={goBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <div className='pb-2'>{selectedSubcategory.subcategoryName}</div>
                    <span className=' pb-2 border-b-2 border-blue-500 border-solid'></span>
                    {selectedSubcategory.topics && selectedSubcategory.topics.map((topic, index) => (
                        <div>
                            <img src={urlFor(client, topic.image).url()} className="max-w-xxl pb-2" alt={topic.topicTitle} />
                            <div key={topic._key}>
                                <div className='pb-2 text-lg font-bold'>{topic.topicTitle}</div>
                                <PortableText
                                    value={topic.content}
                                    components={myPortableTextComponents}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default SubcategoryDetails;
