// App.js
import React, { useEffect, useState, createContext, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from 'react-router-dom';
// import { useSanityClient } from './components/SanityClientContext';
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import Home from './pages/Home';
import Info from './pages/Info';
import Map from './pages/Map';
import Footer from './components/Footer';
import LoadingPage from './components/LoadingPage';

export const GuidebookContext = createContext();

function FooterWrapper() {
  const navigate = useNavigate();
  const navigateTo = (path) => { navigate(path); };
  return <Footer navigateTo={navigateTo} />;
}

function App() {
  const client = useMemo(() =>
    createClient({
      projectId: '5jjvuf7e',
      dataset: 'production',
      useCdn: false,
      apiVersion: 'v2021-10-21',
    }), []
  );

  const builder = imageUrlBuilder(client);

  function urlFor(source) {
    return builder.image(source)
  }
  const { guide_id } = useParams();
  const [guideData, setGuideData] = useState(null);
  const [apartmentData, setApartmentData] = useState(null);
  // useEffect(() => {
  //   client.fetch(`*[_type == "guide" && _id == "${guide_id}"]`)
  //     .then((data) => setGuideData(data[0]))
  //     .catch(console.error);
  // }, [guide_id, client]);
  // console.log(guide_id)
  // useEffect(() => {
  //   if (guideData) {
  //     const apartment_id = guideData.apartment._ref;
  //     client.fetch(`*[_type == "apartment" && _id == "${apartment_id}"]`)
  //       .then((data) => setApartmentData(data[0]))
  //       .catch(console.error);
  //   }
  // }, [guideData, client]);

  useEffect(() => {
    client.fetch(`*[_type == "guide" && _id == "${guide_id}"]`)
      .then((data) => {
        // Iterate over data to find images
        const updatedData = data.map(item => {
          // Check for image property
          if (item.image) {
            return {
              ...item,
              imageUrl: urlFor(item.image)
            }
          } else {
            return item;
          }
        });

        setGuideData(updatedData[0]);
        const apartment_id = updatedData[0]?.apartment._ref;
        return client.fetch(`*[_type == "apartment" && _id == "${apartment_id}"]`);
      })
      .then((data) => {
        // Similar operation for apartment data
        const updatedData = data.map(item => {
          // Check for image property
          if (item.image) {
            return {
              ...item,
              imageUrl: urlFor(item.image)
            }
          } else {
            return item;
          }
        });

        setApartmentData(updatedData[0]);
      })
      .catch(console.error);
  }, [guide_id, client]);


  // console.log(guideData, apartmentData)

  return (
    <GuidebookContext.Provider value={{ guideData, apartmentData }}>
      {guideData && apartmentData ? (
        <div className="flex flex-col h-screen">
          <div className="flex-grow">
            <Routes>
              <Route path="/:guide_id/" element={<Info guide_id={guide_id} />} />
              <Route element={<Map guide_id={guide_id} />} />
              <Route index element={<Home guide_id={guide_id} />} />
            </Routes>
          </div>
          <FooterWrapper />
        </div>
      ) : (
        <LoadingPage />
      )}
    </GuidebookContext.Provider>
  );
}

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/:guide_id/*" element={<App />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;

