import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function CategoriesSidebar({ categories, openedCategory, handleCategoryClick, handleSubcategoryClick }) {
    


    return (
        <div className="w-full min-h-screen flex flex-col bg-white rounded-t-none space-y-4 ease-in-out">
            {categories.map((category, i) => (
                <div key={i} className="w-full pl-4 pr-4 mt-2">
                    <button
                        className={`w-full p-2 text-left flex justify-between items-center font-bold text-xl bg-blue-500 text-white hover:bg-blue-600 focus:outline-none rounded shadow ${openedCategory === i ? 'rounded-b-none' : ''} `}
                        onClick={() => handleCategoryClick(i)}
                    >
                        {category.categoryName}
                        <div className={`transform transition-transform duration-500 ${openedCategory === i ? 'rotate-180' : ''}`}>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                    </button>
                    <div className={`overflow-hidden transition-all duration-500 ease-in-out ${openedCategory === i ? 'max-h-96' : 'max-h-0'}`}>
                        {category.subcategories.map((subcategory, j) => (
                            <div
                                key={j}
                                onClick={() => handleSubcategoryClick(subcategory)}
                                className={`w-full border-t text-left flex justify-between items-center border-blue-400 bg-blue-200 cursor-pointer hover:bg-blue-300 p-2 rounded-b ${j === category.subcategories.length - 1 ? 'rounded-b' : 'rounded-none'} shadow`}
                            >
                                {subcategory.subcategoryName}
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CategoriesSidebar;
