import React, { useState, useEffect, useContext } from 'react';
import HostInfoBar from '../components/HostInfoBar';
import CategoriesSidebar from '../components/CategoriesSidebar';
import SubcategoryDetails from '../components/SubcategoryDetails';
import { motion } from "framer-motion";
import { useSanityClient } from '../components/SanityClientContext';
import LoadingPage from '../components/LoadingPage';
import TopBar from '../components/TopBar';
import { GuidebookContext } from '../App';
const sidebarVariants = {
    open: { x: 0, transition: { type: "spring", stiffness: 300, damping: 30 } },
    closed: { x: "-100%", transition: { duration: 0.5 } }
};

const detailsVariants = {
    open: { x: 0, transition: { type: "spring", stiffness: 300, damping: 30 } },
    closed: { x: "100%", transition: { duration: 0.5 } }
};

function Info() {
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [openedCategory, setOpenedCategory] = useState(null);
    const [showSidebar, setShowSidebar] = useState(true);
    const client = useSanityClient();
    const [categories, setCategories] = useState([]);
    const { guideData } = useContext(GuidebookContext);

    useEffect(() => {
        // Use categoriesData from context instead of fetching
        const categoriesData = guideData.categories;
        setCategories(categoriesData);
        if (window.innerWidth >= 769) {
          setOpenedCategory(categoriesData[0]);
          setSelectedSubcategory(categoriesData[0].subcategories[0]);
        }
      }, [client, guideData]);
    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             const data = await client.fetch(`*[_type == "guide"]`);
    //             const categoriesData = data[0].categories;
    //             setCategories(categoriesData);
    //             if (window.innerWidth >= 769) {
    //                 setOpenedCategory(categoriesData[0]);
    //                 setSelectedSubcategory(categoriesData[0].subcategories[0]);
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }
    //     fetchData();
    // }, [client]);

    const handleCategoryClick = (index) => {
        if (openedCategory === index) {
            setOpenedCategory(null);
        } else {
            setOpenedCategory(index);
        }
    };

    const handleSubcategoryClick = (subcategory) => {
        setSelectedSubcategory(subcategory);
        if (window.innerWidth <= 768) {
            setShowSidebar(false);
        }
    };

    if (!categories.length) return <LoadingPage /> // Add loading state

    return (
        <div className='content-page w-full h-full bg-gray-100 duration-500 animate-fadeIn'>
            <div className="w-full lg:w-4/5 h-screen lg:p-10 m-auto flex flex-col">
                {/* <HostInfoBar /> */}
                <TopBar />
                <div className="flex flex-row w-full overflow-x-hidden lg:overflow-visible">
                    <div className={`lg:static lg:translate-x-0 transform transition-transform duration-500 ease-in-out ${showSidebar ? 'translate-x-0' : '-translate-x-full'} w-full lg:w-1/3 lg:block ${showSidebar ? 'block' : 'hidden'}`}>
                        {window.innerWidth > 768 ?
                            <CategoriesSidebar
                                categories={categories}
                                openedCategory={openedCategory}
                                handleCategoryClick={handleCategoryClick}
                                handleSubcategoryClick={handleSubcategoryClick}
                            /> :
                            <motion.div
                                className={`w-full lg:w-1/3`}
                                animate={showSidebar ? 'open' : 'closed'}
                                variants={sidebarVariants}
                            >
                                <CategoriesSidebar
                                    categories={categories}
                                    openedCategory={openedCategory}
                                    handleCategoryClick={handleCategoryClick}
                                    handleSubcategoryClick={handleSubcategoryClick}
                                />
                            </motion.div>
                        }
                    </div>
                    <div className={`lg:static lg:translate-x-0 transform transition-transform duration-500 ease-in-out ${showSidebar ? '-translate-x-full' : 'translate-x-0'} w-full lg:w-2/3 lg:block ${showSidebar ? 'hidden' : 'block'}`}>
                        {window.innerWidth > 768 ?
                            <div>
                                <SubcategoryDetails selectedSubcategory={selectedSubcategory} goBack={() => setShowSidebar(true)} />
                            </div> :
                            <motion.div
                                className={`w-full lg:w-2/3`}
                                animate={showSidebar ? 'closed' : 'open'}
                                variants={detailsVariants}
                            >
                                <SubcategoryDetails selectedSubcategory={selectedSubcategory} goBack={() => setShowSidebar(true)} />
                            </motion.div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;
