import React, { useState, useEffect, useRef } from 'react';
import { useSanityClient } from '../components/SanityClientContext';
import imageUrlBuilder from '@sanity/image-url';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUmbrellaBeach, faStar, faStarHalfAlt, faChevronDown, faPhoneAlt, faGlobe, faMapMarkedAlt, faCircleInfo,faCoffee, faUtensils, faQuestion, faHome, faGlassCheers, faDice, faLeaf, faGasPump, faShoppingCart, faClinicMedical, faLandmark, faPrescriptionBottleAlt, faChurch, faBinoculars } from '@fortawesome/free-solid-svg-icons';import L from 'leaflet';
// Import your custom SVG files
import HomeIconPin from '../assets/icons/home.svg';
import CoffeeIconPin from '../assets/icons/coffee_shop.svg';
import BeachPin from '../assets/icons/beaches.svg';
import BarNightlifePin from '../assets/icons/bars_and_nightlife.svg';
import EntertainmentPin from '../assets/icons/entertainment_and_activities.svg';
import FarmShopPin from '../assets/icons/farm_shop_market.svg';
import GasStationPin from '../assets/icons/gas_station.svg';
import GroceryPin from '../assets/icons/grocery_shopping.svg';
import MedicalFacilitiesPin from '../assets/icons/medical_facilities.svg';
import MuseumsPin from '../assets/icons/museums.svg';
import PhramacyPin from '../assets/icons/pharmacy.svg';
import PlaceOfWorshipPin from '../assets/icons/place_of_worship.svg';
import RestaurantPin from '../assets/icons/restaurants.svg';
import SightseeingPin from '../assets/icons/sightseeing.svg';

// Replace './Spinner' with your spinner component's actual path
import Spinner from '../components/Spinner';

// Map of icon names to their SVG files
const iconFiles = {
    home: HomeIconPin,
    coffee: CoffeeIconPin,
    beach: BeachPin,
    bars: BarNightlifePin,
    entertainment: EntertainmentPin,
    farm: FarmShopPin,
    gas: GasStationPin,
    grocery: GroceryPin,
    medical: MedicalFacilitiesPin,
    museums: MuseumsPin,
    pharmacy: PhramacyPin,
    worship: PlaceOfWorshipPin,
    restaurant: RestaurantPin,
    sightseeing: SightseeingPin
};

// // Function to create a new `L.Icon` instance
function createIcon(iconName, size = [40, 40]) {
    const iconUrl = iconFiles[iconName];
    if (!iconUrl) throw new Error(`Unknown icon name: ${iconName}`);
    return new L.Icon({ iconUrl, iconSize: size });
}

// // Now you can create new `L.Icon` instances like this:
const coffeeIcon = createIcon('coffee');
const homeIcon = createIcon('home', [50, 50]);
const beachIcon = createIcon('beach');
const barIcon = createIcon('bars');
const entertainmentIcon = createIcon('entertainment');
const farmIcon = createIcon('farm');
const gasIcon = createIcon('gas');
const groceryIcon = createIcon('grocery');
const medicalIcon = createIcon('medical');
const museumsIcon = createIcon('museums');
const pharmacyIcon = createIcon('pharmacy');
const worshipIcon = createIcon('worship');
const restaurantIcon = createIcon('restaurant');
const sightseeingIcon = createIcon('sightseeing');

// function getIconForType(type) {
//     switch (type) {
//         case 'cafe':
//             return faCoffee;
//         case 'home':
//             return faHome;
//         case 'beach':
//             return faUmbrellaBeach;
//         case 'bar':
//             return faGlassCheers;
//         case 'entertainment':
//             return faDice;
//         case 'farm':
//             return faLeaf;
//         case 'gas':
//             return faGasPump;
//         case 'grocery':
//             return faShoppingCart;
//         case 'medical':
//             return faClinicMedical;
//         case 'museums':
//             return faLandmark;
//         case 'pharmacy':
//             return faPrescriptionBottleAlt;
//         case 'worship':
//             return faChurch;
//         case 'food':
//             return faUtensils;
//         case 'tourist_attraction':
//             return faBinoculars;
//         default:
//             return null; // some default icon, e.g., question mark
//     }
// }


function getIconForCategory(type) {
    switch (type) {
        case 'cafe':
            return coffeeIcon;
        case 'home':
            return homeIcon;
        case 'beach':
            return beachIcon;
        case 'bar':
            return barIcon;
        case 'entertainment':
            return entertainmentIcon;
        case 'farm':
            return farmIcon;
        case 'gas':
            return gasIcon;
        case 'grocery':
            return groceryIcon;
        case 'medical':
            return medicalIcon;
        case 'museum':
            return museumsIcon;
        case 'pharmacy':
            return pharmacyIcon;
        case 'place_of_worship':
            return worshipIcon;
        case 'food':
            return restaurantIcon;
        case 'tourist_attraction':
            return sightseeingIcon;
        default:
            return null; // some default icon, e.g., question mark
    }
}


function generateStars(rating) {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
        if (rating >= i) {
            stars.push(<FontAwesomeIcon key={i} icon={faStar} />);
        } else if (rating >= i - 0.5) {
            stars.push(<FontAwesomeIcon key={i} icon={faStarHalfAlt} />);
        } else {
            stars.push(<FontAwesomeIcon key={i} icon={['far', 'star']} />); // far star is the empty star icon
        }
    }
    return stars;
}

function DropdownCheckbox({ categories, handleFilterClick }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className='absolute right-0 mt-2 mr-2' style={{ zIndex: 1000 }}>
            <button onClick={() => setIsOpen(!isOpen)} className=' text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center'>
                Filter places <FontAwesomeIcon icon={faChevronDown} className={`w-4 h-4 ml-2 transition-transform duration-200 ${isOpen && 'transform rotate-180'}`} />
            </button>
            {isOpen && (
                <div className="origin-top-right  mt-2 max-w-10  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <ul className="p-3 space-y-1 text-sm text-gray-700" aria-labelledby="dropdownHelperButton">
                        {categories.map((category, index) => (
                            <li key={index}>
                                <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                    <div className="flex items-center h-5">
                                        <input id={`helper-checkbox-${index}`} type="checkbox" checked={category.selected} onChange={() => handleFilterClick(category)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                    </div>
                                    <div className="ml-2 text-sm">
                                        <label htmlFor={`helper-checkbox-${index}`} className="font-medium text-gray-900 dark:text-gray-300">
                                            <div>{category.name}</div>
                                        </label>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

function Map({ guideData }) {
    const client = useSanityClient();
    const guide_id = guideData._id;
    const [loading, setLoading] = useState(true);
    const [apartment, setApartment] = useState(null);
    const [places, setPlaces] = useState([]);
    const [filters, setFilters] = useState({
        isOpen: false,
        categories: [
            // { name: 'Cafe', icon: coffeeIcon, selected: true },
            { name: 'Home', icon: homeIcon, selected: true },
            // { name: 'Beach', icon: beachIcon, selected: true },
            // { name: 'Bars', icon: barIcon, selected: true },
            // { name: 'Entertainment', icon: entertainmentIcon, selected: true },
            // { name: 'Farm', icon: farmIcon, selected: true },
            // { name: 'Gas', icon: gasIcon, selected: true },
            // { name: 'Grocery', icon: groceryIcon, selected: true },
            // { name: 'Medical', icon: medicalIcon, selected: true },
            // { name: 'Museums', icon: museumsIcon, selected: true },
            // { name: 'Pharmacy', icon: pharmacyIcon, selected: true },
            // { name: 'Worship', icon: worshipIcon, selected: true },
            // { name: 'Restaurant', icon: restaurantIcon, selected: true },
            // { name: 'Sightseeing', icon: sightseeingIcon, selected: true },

            // Add more filters as needed
        ]
    });

    const handleFilterClick = (category) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            categories: prevFilters.categories.map(item =>
                item.name === category.name ? { ...item, selected: !item.selected } : item
            )
        }));
    };

    // Fetch apartment and places data during page load
    useEffect(() => {
        client
            .fetch(`*[_type == "guide" && _id == "${guide_id}"]{
          _id,
          title,
          description,
          "apartment": apartment->,
          "places": places[]-> 
        }`)
            .then((data) => {
                const guide = data[0];
                const places = guide.places.map(place => ({
                    place_id: place._id,
                    name: place.placeInfo.name,
                    location: place.placeInfo.geolocation,
                    formatted_address: place.placeInfo.address,
                    international_phone_number: place.placeInfo.phone_number,
                    types: place.placeInfo.categories,
                    image_url: place.placeInfo.image_url, // imageUrlBuilder.image(place.image).url()
                    rating: parseFloat(place.placeInfo.rating),
                    url: place.placeInfo.google_maps_url,
                    guide_url: "#",
                }));
                setApartment({
                    place_id: guide.apartment._id,
                    title: guide.apartment.title,
                    address: guide.apartment.address,
                    location: guide.apartment.geolocation,
                    image: guide.apartment.image,
                });
                setPlaces(places);
                setLoading(false); // Set loading to false after data is set
            })
            .catch(error => {
                console.error(error);
                setLoading(false); // Ensure loading is set to false in case of error as well
            });
    }, []);

    // Display a loading animation while the data is being loaded
    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spinner />
                {/* Replace <Spinner /> with your own loading component or text */}
            </div>
        );
    }

    // Once the data is loaded, render the MapContainer component
    return (
        <div className="absolute inset-0 flex-grow animate-fadeIn">
            <MapContainer
                center={apartment.location || [35.5155318, 24.025821]}
                zoom={17}
                style={{ height: "92vh", width: "100%" }}
            >
                <DropdownCheckbox categories={filters.categories} handleFilterClick={handleFilterClick} />
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                />
                {places.map(place => (
                    <Marker position={place.location} icon={getIconForCategory(place.types[0])} key={place.place_id}>
                        <Popup>
                            <div className="text-center p-2 transform transition-transform duration-500 ease-in-out hover:scale-105">
                                {/* <div className="flex justify-center pb-1 text-blue-500">
                                    {place.types.map((type, index) => (
                                        <FontAwesomeIcon key={index} icon={getIconForType(type)} className="mx-1" />
                                    ))}
                                </div> */}
                                <div className="flex justify-center  pb-2 text-yellow-500 mb-2">
                                    {generateStars(place.rating)}
                                </div>
                                {(place.image_url) && <img src={place.image_url} alt="apartment" className="rounded-full w-20 h-20 mx-auto" />}
                                <h2 className="font-semibold text-2xl text-blue-500 mb-1">{place.name}</h2>
                                <div className="text-gray-700 mb-2">{place.formatted_address}</div>
                                <div className="flex justify-around text-2xl">
                                    <a href={`tel:${place.international_phone_number}`} className="text-blue-500 hover:underline">
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </a>
                                    <a href={place.website} target='_blank' rel="noreferrer" className="text-blue-500 hover:underline">
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </a>
                                    <a href={place.url} target='_blank' rel="noreferrer" className="text-blue-500 hover:underline">
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </a>
                                    {place.guide_url && <a href={place.guide_url} className="text-blue-500 hover:underline">
                                        <FontAwesomeIcon icon={faCircleInfo} />
                                    </a>
                                    }
                                </div>
                                {place.guide_url ? <div className='pt-4 text-xs pb-0'>Rating is based on Google Ratings. Click the info item to find out more!</div>
                                    : <div className='pt-4 text-xs pb-0'>Rating is based on Google Ratings</div>
                                }
                            </div>
                        </Popup>
                    </Marker>
                ))}
                {apartment && <Marker position={apartment.location} icon={homeIcon}>
                    <Popup>
                        <div className="text-center p-2 transform transition-transform duration-500 ease-in-out hover:scale-105">
                            {/* <img src={imageUrl} alt="apartment" className="rounded-full w-20 h-20 mx-auto" /> */}
                            <h2 className="font-semibold text-2xl text-blue-500 mb-1">{apartment.title}</h2>
                            <div className="text-gray-700 mb-2">{apartment.address}</div>
                            <div className="flex justify-around text-2xl">
                                <a href={`tel:${apartment.phone_number}`} className="text-blue-500 hover:underline">
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                </a>
                                <a href={apartment.website} target='_blank' rel="noreferrer" className="text-blue-500 hover:underline">
                                    <FontAwesomeIcon icon={faGlobe} />
                                </a>
                                <a href={apartment.url} target='_blank' rel="noreferrer" className="text-blue-500 hover:underline">
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                </a>
                            </div>
                        </div>
                    </Popup>
                </Marker>
                }

            </MapContainer>
        </div>
    );
}

export default Map;
