import React, { useEffect, useStatem, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useSanityClient } from '../components/SanityClientContext';
import imageUrlBuilder from '@sanity/image-url';
import { GuidebookContext } from '../App';
import NotFound from '../components/NotFound';

function Home() {
    const { guideData, apartmentData } = useContext(GuidebookContext);
    // const client = useSanityClient();
    console.log(guideData, apartmentData)
    if (!guideData || !apartmentData) {
        return (<NotFound />)
    } else {
        return (
            <div
                className="content-page relative h-screen flex items-center justify-center bg-cover bg-center animate-fadeIn"
                style={{ backgroundImage: `url(${apartmentData.imageUrl})` }}
            >
                <div
                    className="absolute inset-0 bg-black opacity-50 backdrop-filter backdrop-blur-lg"
                    aria-hidden="true"
                />

                <div className="flex flex-col items-center relative animate-fade-in-down">
                    <img src={apartmentData.imageUrl} alt="Host" className="w-32 h-32 object-cover rounded-full mb-4" />
                    <p className="text-4xl text-white font-bold mb-2">Welcome!</p>
                    <p className="text-xl text-white mb-4">{apartmentData.title}, </p>
                    <p className="text-xl text-white mb-4">{apartmentData.address} </p>
                    <div className="flex space-x-4">
                        <a href="tel:123-456-7890" className="text-2xl text-white animate-fade-in-down delay-200">
                            <FontAwesomeIcon icon={faPhone} />
                        </a>
                        <a href="mailto:example@example.com" className="text-2xl text-white animate-fade-in-down delay-300">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
